import React, { useRef, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useApi } from '../contexts/ApiContext'
import { Link, Navigate } from 'react-router-dom'

export default function AccountPage() {
  const { currentUser, updateCurrentUser } = useAuth()
  const { apiFetch } = useApi()

  const usernameRef = useRef()
  const displayNameRef = useRef()
  const passwordRef = useRef()
  const confirmPasswordRef = useRef()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    const res = await apiFetch('/auth/account', 'PUT', {
      username: usernameRef.current.value.trim(),
      displayName: displayNameRef.current.value.trim(),
      password: passwordRef.current.value,
      confirmPassword: confirmPasswordRef.current.value,
    })
    if (!res.ok) {
      const data = await res.json()
      setLoading(false)
      setError({
        detail: data.detail || 'Unable to update account.',
        errors: data?.errors,
      })
    } else {
      updateCurrentUser()
      setLoading(false)
    }
  }

  if (!currentUser) return <Navigate to="/" />

  return (
    <>
      <div
        className="container flex flex-col gap-4 items-center"
        style={{ paddingTop: '64px', paddingBottom: '64px' }}
      >
        <div className="card w-full max-w-30">
          <div className="card-body flex flex-col gap-4">
            <h2 className="gochi-hand">Account</h2>
            <form
              onSubmit={handleSubmit}
              className="form"
            >
              <div className="form-group required">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  autoComplete="off"
                  autoCapitalize="off"
                  id="username"
                  className={`form-control ${
                    error &&
                    error.errors &&
                    Object.keys(error.errors).includes('username')
                      ? 'error'
                      : ''
                  }`}
                  ref={usernameRef}
                  defaultValue={currentUser.username}
                  disabled={loading}
                />
              </div>
              <div className="form-group required">
                <label htmlFor="displayName">Display Name</label>
                <input
                  type="text"
                  autoComplete="off"
                  autoCapitalize="off"
                  id="username"
                  className={`form-control ${
                    error &&
                    error.errors &&
                    Object.keys(error.errors).includes('displayName')
                      ? 'error'
                      : ''
                  }`}
                  ref={displayNameRef}
                  defaultValue={currentUser.displayName}
                  disabled={loading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  Password (Leave blank to keep same password)
                </label>
                <input
                  type="password"
                  autoComplete="off"
                  autoCapitalize="off"
                  id="password"
                  className={`form-control ${
                    error &&
                    error.errors &&
                    Object.keys(error.errors).includes('password')
                      ? 'error'
                      : ''
                  }`}
                  ref={passwordRef}
                  disabled={loading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  autoComplete="off"
                  autoCapitalize="off"
                  id="confirmPassword"
                  className={`form-control ${
                    error &&
                    error.errors &&
                    Object.keys(error.errors).includes('confirmPassword')
                      ? 'error'
                      : ''
                  }`}
                  ref={confirmPasswordRef}
                  disabled={loading}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Update Account
              </button>
              {error && (
                <div className="form-dialog form-dialog-error">
                  <p>{error.detail}</p>
                  {error && error.errors && (
                    <ul>
                      {[...new Set(Object.values(error.errors))].map((v, i) => {
                        return (
                          <li
                            key={i}
                            style={{ marginLeft: '16px' }}
                          >
                            {v}
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              )}
              <p style={{ fontSize: '16px' }}>
                <Link
                  to="/"
                  className="link"
                >
                  Back
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
