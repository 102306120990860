import React from 'react'
import ReactDOM from 'react-dom/client'
import './css/main.css'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { ApiProvider } from './contexts/ApiContext'
import { AuthProvider } from './contexts/AuthContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Router>
      <ApiProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ApiProvider>
    </Router>
  </React.StrictMode>
)
