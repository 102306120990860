const deleteImage = async (link) => {
  const matches = link.match(
    /^(https?:\/\/)?([^.]*\.imgur\.com)?\/?([^.]*)(\..*)?$/
  )
  if (!matches) return null

  const accessData = new FormData()
  accessData.append('refresh_token', process.env.REACT_APP_IMGUR_REFRESH_TOKEN)
  accessData.append('client_id', process.env.REACT_APP_IMGUR_CLIENT_ID)
  accessData.append('client_secret', process.env.REACT_APP_IMGUR_CLIENT_SECRET)
  accessData.append('grant_type', 'refresh_token')
  const accessRes = await fetch('https://api.imgur.com/oauth2/token', {
    method: "POST",
    body: accessData
  })
  if (!accessRes.ok) return null
  const accessResData = await accessRes.json()
  const accessToken = await accessResData.access_token

  return fetch(`https://api.imgur.com/3/image/${matches[3]}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  })
}

export default deleteImage
