const uploadImage = async (file) => {
  const data = new FormData()
  data.append('image', file)
  return fetch('https://api.imgur.com/3/image', {
    method: 'POST',
    headers: {
      Authorization: `Client-ID ${process.env.REACT_APP_IMGUR_CLIENT_ID}`,
    },
    body: data,
  })
}

export default uploadImage
