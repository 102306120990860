import React from 'react'
import { Routes, Route } from 'react-router-dom'

import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import AccountPage from './pages/AccountPage'
import CreateMemoryPage from './pages/memories/CreateMemoryPage'
import MemoryPage from './pages/memories/MemoryPage'
import DeleteMemoryPage from './pages/memories/DeleteMemoryPage'

export default function App() {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<HomePage />}
      />
      <Route
        path="/login"
        element={<LoginPage />}
      />
      <Route
        path="/account"
        element={<AccountPage />}
      />
      <Route
        path="/create"
        element={<CreateMemoryPage />}
      />
      <Route
        path="/memory/:postId"
        element={<MemoryPage />}
      />
      <Route
        path="/memory/:postId/delete"
        element={<DeleteMemoryPage />}
      />
    </Routes>
  )
}
