import React, { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useApi } from '../../contexts/ApiContext'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'

export default function MemoryPage() {
  const { currentUser } = useAuth()
  const { apiFetch } = useApi()
  const navigate = useNavigate()

  const { postId } = useParams()

  const [post, setPost] = useState(null)
  const updatePost = async () => {
    const res = await apiFetch(`/posts/${postId}`)
    if (!res.ok) {
      return navigate('/')
    } else {
      const data = await res.json()
      setPost(data.post)
    }
  }
  useEffect(() => {
    if (!postId) return navigate('/')
    if (currentUser && postId) updatePost()
  }, [])

  if (!currentUser) return <Navigate to="/" />

  return (
    <>
      <div
        className="container flex flex-col gap-4 items-center"
        style={{ paddingTop: '32px', paddingBottom: '32px' }}
      >
        {!post ? (
          <div style={{ marginTop: '64px' }}>
            <Spinner />
          </div>
        ) : (
          <>
            <div
              className="card w-full max-w-30"
              key={post.postId}
            >
              <div className="card-header">
                <h3 className="gochi-hand">{post.title}</h3>
                <p style={{ fontSize: '16px' }}>
                  {post.dateYear}-{String(post.dateMonth + 1).padStart(2, '0')}-
                  {String(post.dateDay).padStart(2, '0')}
                </p>
              </div>
              <div className="card-body flex justify-center">
                <img
                  src={post.image}
                  alt={post.title}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                />
              </div>
              <div className="card-footer flex flex-col gap-2">
                <small>
                  Created by {post.authorDisplayName} ({post.authorUsername})
                </small>
                {post.description && <p>{post.description}</p>}
              </div>
            </div>
            <div className="w-full max-w-30 flex justify-between items-center">
              <p>
                <Link
                  to="/"
                  className="link"
                  style={{ fontSize: '16px' }}
                >
                  Back
                </Link>
              </p>
              <p>
                <Link
                  to={`/memory/${postId}/delete`}
                  className="link-red"
                  style={{ fontSize: '16px' }}
                >
                  Delete
                </Link>
              </p>
            </div>
          </>
        )}
      </div>
    </>
  )
}
