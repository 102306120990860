import React, { useContext } from 'react'
import generalizePath from '../functions/generalizePath'

const ApiContext = React.createContext()

export const useApi = () => {
  return useContext(ApiContext)
}

export const ApiProvider = ({ children }) => {
  const apiFetch = (path, method = 'GET', body = null) => {
    return new Promise(async (resolve) => {
      const reqData = { method, headers: {}, body: null }
      if (body) {
        reqData.headers['Content-Type'] = 'application/json'
        reqData.body = JSON.stringify(body)
      }
      const res = await fetch(`/api/${generalizePath(path)}`, reqData)
      resolve(res)
    })
  }

  const value = {
    apiFetch,
  }

  return (
    <ApiContext.Provider value={value}>
      {children && children}
    </ApiContext.Provider>
  )
}
