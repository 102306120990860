import React, { useEffect, useState } from 'react'
import { useApi } from '../../contexts/ApiContext'
import { useAuth } from '../../contexts/AuthContext'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import deleteImage from '../../functions/deleteImage'

export default function DeleteMemoryPage() {
  const { currentUser } = useAuth()
  const { apiFetch } = useApi()
  const navigate = useNavigate()

  const { postId } = useParams()

  const [post, setPost] = useState(null)
  const updatePost = async () => {
    const res = await apiFetch(`/posts/${postId}`)
    if (!res.ok) {
      return navigate('/')
    } else {
      const data = await res.json()
      if (
        currentUser.userId !== data.post.author &&
        currentUser.permissionLevel < 1
      ) {
        return navigate('/')
      } else {
        setPost(data.post)
      }
    }
  }
  useEffect(() => {
    if (!postId) return navigate('/')
    if (currentUser && postId) updatePost()
  }, [])

  const [loading, setLoading] = useState(false)
  const handleDelete = async () => {
    setLoading(true)
    await deleteImage(post.image)
    const res = await apiFetch(`/posts/${postId}/delete`, 'DELETE')
    if (!res.ok) {
      setLoading(false)
    } else {
      navigate('/')
    }
  }

  if (!currentUser) return <Navigate to="/" />

  return (
    <>
      <div
        className="container flex flex-col gap-4 items-center"
        style={{ paddingTop: '64px', paddingBottom: '64px' }}
      >
        {!post ? (
          <Spinner />
        ) : (
          <div className="card w-full max-w-30">
            <div className="card-body flex flex-col gap-4">
              <p>
                Are you sure you want do delete <strong>{post.title}</strong>?
              </p>
              <div className="w-full flex flex-col gap-2">
                <button
                  className="btn btn-error"
                  disabled={loading}
                  onClick={handleDelete}
                >
                  Yes
                </button>
                <Link
                  to={`/memory/${postId}`}
                  role="button"
                  className="btn btn-primary"
                >
                  No
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
