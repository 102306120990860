import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useApi } from '../../contexts/ApiContext'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import imageCompression from 'browser-image-compression'
import uploadImage from '../../functions/uploadImage'

export default function CreatePostPage() {
  const { currentUser } = useAuth()
  const { apiFetch } = useApi()
  const navigate = useNavigate()

  const [file, setFile] = useState(null)
  const handleChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setLoading(true)
      try {
        const compressedFile = await imageCompression(e.target.files[0], {
          maxSizeMB: 5,
        })
        setFile(compressedFile)
        setLoading(false)
      } catch (e) {
        setFile(null)
        setError(e.message || 'Error opening file.')
      }
    } else {
      setFile(null)
    }
  }
  const titleRef = useRef()
  const descriptionRef = useRef()
  const dateRef = useRef()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    const imgurRes = await uploadImage(file)
    const data = await imgurRes.json()
    if (!data.success) {
      return setError(data.data.error)
    } else {
      const res = await apiFetch('/posts/create', 'POST', {
        title: titleRef.current.value.trim(),
        description: descriptionRef.current.value.trim(),
        image: data.data.link,
        date: dateRef.current.value,
      })
      if (!res.ok) {
        const data = await res.json()
        setLoading(false)
        setError({
          detail: data.detail || 'Unable to add memory.',
          errors: data.errors,
        })
      } else {
        navigate('/')
      }
    }
  }
  useEffect(() => {
    if (error) console.log(error)
  }, [error])

  if (!currentUser) return <Navigate to="/" />

  return (
    <>
      <div
        className="container flex flex-col gap-4 items-center"
        style={{ paddingTop: '64px', paddingBottom: '64px' }}
      >
        <div className="card w-full max-w-30">
          <div className="card-body flex flex-col gap-4">
            <h2 className="gochi-hand">Add Memory</h2>
            <form
              onSubmit={handleSubmit}
              className="form"
            >
              <div className="form-group required">
                <label htmlFor="image">Image</label>
                <input
                  type="text"
                  value={file ? file.name : 'Upload file'}
                  readOnly
                  className={`form-control ${
                    error &&
                    error.errors &&
                    Object.keys(error.errors).includes('image')
                      ? 'error'
                      : ''
                  }`}
                  onClick={() => document.getElementById('image').click()}
                  disabled={loading}
                />
                <input
                  type="file"
                  accept="image/*"
                  id="image"
                  onChange={handleChange}
                  style={{
                    position: 'absolute',
                    transform: 'translateX(-10000px)',
                  }}
                  disabled={loading}
                />
              </div>
              <div className="form-group required">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  autoComplete="off"
                  autoCapitalize="off"
                  id="title"
                  className={`form-control ${
                    error &&
                    error.errors &&
                    Object.keys(error.errors).includes('title')
                      ? 'error'
                      : ''
                  }`}
                  ref={titleRef}
                  disabled={loading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  rows={5}
                  autoComplete="off"
                  autoCapitalize="off"
                  id="description"
                  className={`form-control ${
                    error &&
                    error.errors &&
                    Object.keys(error.errors).includes('description')
                      ? 'error'
                      : ''
                  }`}
                  ref={descriptionRef}
                  disabled={loading}
                />
              </div>
              <div className="form-group required">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  autoComplete="off"
                  autoCapitalize="off"
                  id="date"
                  className={`form-control ${
                    error &&
                    error.errors &&
                    Object.keys(error.errors).includes('date')
                      ? 'error'
                      : ''
                  }`}
                  style={{ minWidth: 'calc(100% - 18px)' }}
                  ref={dateRef}
                  disabled={loading}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Create Memory
              </button>
              {error && (
                <div className="form-dialog form-dialog-error">
                  <p>{error.detail}</p>
                  {error && error.errors && (
                    <ul>
                      {[...new Set(Object.values(error.errors))].map((v, i) => {
                        return (
                          <li
                            key={i}
                            style={{ marginLeft: '16px' }}
                          >
                            {v}
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              )}
              <p style={{ fontSize: '16px' }}>
                <Link
                  to="/"
                  className="link"
                >
                  Back
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
