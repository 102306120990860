import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { useApi } from '../contexts/ApiContext'
import useDebounce from '../functions/useDebounce'

import Spinner from '../components/Spinner'

export default function HomePage() {
  const { currentUser, logOut } = useAuth()
  const { apiFetch } = useApi()

  const [q, setQ] = useState('')
  const debouncedSearch = useDebounce(q)
  const handleSearch = (e) => {
    setQ(e.target.value)
  }
  useEffect(() => {
    updatePosts(debouncedSearch)
  }, [debouncedSearch])

  const [posts, setPosts] = useState(null)
  const updatePosts = async (query = '') => {
    const res = await apiFetch(`/posts${query ? `?q=${query}` : ''}`)
    if (res.ok) {
      const data = await res.json()
      setPosts(data.posts.data)
    } else {
      setPosts([])
    }
  }
  useEffect(() => {
    if (currentUser) updatePosts()
  }, [])

  const [loading, setLoading] = useState(false)
  const handleLogout = async () => {
    setLoading(true)
    await logOut()
    setLoading(false)
  }

  if (!currentUser)
    return (
      <>
        <div
          className="container w-full flex flex-col gap-4 items-center justify-center"
          style={{ paddingTop: '128px', paddingBottom: '128px' }}
        >
          <div className="card w-full max-w-30">
            <div
              className="card-body flex flex-col gap-4 items-center justify-center"
              style={{ minHeight: '128px' }}
            >
              <h1 className="text-center gochi-hand">To memories that last.</h1>
              <Link
                to="/login"
                className="btn btn-primary w-full"
                role="button"
              >
                Log In
              </Link>
            </div>
          </div>
        </div>
      </>
    )

  return (
    <>
      <div className="container flex flex-col gap-4 items-center">
        <h1 className="gochi-hand text-center flex items-center gap-2">
          Our Memories{' '}
          <span
            className="material-symbols-outlined"
            style={{ fontSize: '48px' }}
          >
            favorite
          </span>
        </h1>
        <div className="flex flex-col items-center">
          <p
            className="text-center"
            style={{ fontSize: '16px' }}
          >
            Logged in as {currentUser.username} ({currentUser.displayName})
          </p>
          <small style={{ display: 'block' }}>
            <Link
              to="/account"
              className="link"
            >
              Edit Account
            </Link>{' '}
            |{' '}
            <button
              className="link"
              onClick={handleLogout}
              disabled={loading}
            >
              Log Out
            </button>
          </small>
        </div>
        <div className="w-full flex flex-col gap-2 items-center">
          <input
            type="text"
            autoComplete="off"
            autoCapitalize="off"
            id="search"
            className="form-control w-full max-w-30"
            placeholder="Search our memories by date, title, or description..."
            onChange={handleSearch}
          />
          <Link
            to="/create"
            role="button"
            className="btn btn-primary w-full max-w-30"
          >
            Add Memory{' '}
            <span className="material-symbols-outlined">add_circle</span>
          </Link>
        </div>
        <div
          className="w-full flex flex-row justify-center gap-4"
          style={{ flexWrap: 'wrap' }}
        >
          {!posts ? (
            <Spinner />
          ) : posts.length === 0 ? (
            <p className="text-center">No memories to display.</p>
          ) : (
            posts.map((post) => {
              return (
                <Link
                  to={`/memory/${post.postId}`}
                  key={post.postId}
                  className="w-full max-w-20"
                >
                  <div className="card card-button">
                    <div className="card-body flex justify-center">
                      <img
                        src={post.image}
                        alt={post.title}
                        style={{
                          width: '288px',
                          height: '288px',
                          objectFit: 'cover',
                        }}
                        loading="lazy"
                      />
                    </div>
                    <div className="card-footer">
                      <h3 className="gochi-hand">{post.title}</h3>
                      <p style={{ fontSize: '16px' }}>
                        {post.dateYear}-
                        {String(post.dateMonth + 1).padStart(2, '0')}-
                        {String(post.dateDay).padStart(2, '0')}
                      </p>
                    </div>
                  </div>
                </Link>
              )
            })
          )}
        </div>
      </div>
    </>
  )
}
