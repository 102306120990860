import React, { useRef, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Link, Navigate } from 'react-router-dom'

export default function LoginPage() {
  const { currentUser, logIn } = useAuth()

  const usernameRef = useRef()
  const passwordRef = useRef()
  const stayLoggedInRef = useRef()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    const loginErr = await logIn({
      username: usernameRef.current.value,
      password: passwordRef.current.value,
      stayLoggedIn: stayLoggedInRef.current.checked,
    })
    if (loginErr) {
      setLoading(false)
      setError({
        detail: loginErr.detail || 'Unable to log in.',
        errors: loginErr?.errors,
      })
    }
  }

  if (currentUser) return <Navigate to="/" />

  return (
    <>
      <div
        className="container flex flex-col gap-4 items-center"
        style={{ paddingTop: '64px', paddingBottom: '64px' }}
      >
        <div className="card w-full max-w-30">
          <div className="card-body flex flex-col gap-4">
            <h2 className="gochi-hand">Log In</h2>
            <form
              onSubmit={handleSubmit}
              className="form"
            >
              <div className="form-group required">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  autoComplete="off"
                  autoCapitalize="off"
                  id="username"
                  className={`form-control ${
                    error &&
                    error.errors &&
                    Object.keys(error.errors).includes('username')
                      ? 'error'
                      : ''
                  }`}
                  ref={usernameRef}
                  disabled={loading}
                />
              </div>
              <div className="form-group required">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  autoComplete="off"
                  autoCapitalize="off"
                  id="password"
                  className={`form-control ${
                    error &&
                    error.errors &&
                    Object.keys(error.errors).includes('password')
                      ? 'error'
                      : ''
                  }`}
                  ref={passwordRef}
                  disabled={loading}
                />
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="stayLoggedIn"
                  ref={stayLoggedInRef}
                  disabled={loading}
                />
                <label htmlFor="stayLoggedIn">
                  <small>Keep me logged in</small>
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Log In
              </button>
              {error && (
                <div className="form-dialog form-dialog-error">
                  <p>{error.detail}</p>
                  {error && error.errors && (
                    <ul>
                      {[...new Set(Object.values(error.errors))].map((v, i) => {
                        return (
                          <li
                            key={i}
                            style={{ marginLeft: '16px' }}
                          >
                            {v}
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              )}
              <p style={{ fontSize: '16px' }}>
                <Link
                  to="/"
                  className="link"
                >
                  Back
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
