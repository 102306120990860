import React from 'react'

export default function Spinner({ size = 128, color = '#000' }) {
  return (
    <div
      className="spinner"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderWidth: `${size / 8}px`,
        borderTopColor: color,
      }}
    />
  )
}
